<template>
  <div class="reporting_number">
    <div class="reporting_number_button" v-if="isShow">
      <!-- 文件导出 -->
      <popover @popoverClick="popoverClick" />
      <el-button @click="deleteBatch">
        批量删除
        <i class="el-icon-delete el-icon--right"></i>
      </el-button>
    </div>
    <div class="bodyOne">
      <el-table
        :data="tableData"
        stripe
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        style="width: 100%"
        :default-sort="{ prop: 'tableData', order: 'descending' }"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        class="table ly_table"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column align="center" label="序号">
          <template v-slot="scope">
            <span>{{(queryInfo.pageNum-1)*queryInfo.pageSize+(scope.$index + 1)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="报告名称" align="center" prop="reportName" width="300">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor:pointer;">
              <span>{{row.reportName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="领域类别" align="center" prop="fieldId" width="250" />
        <el-table-column label="行业类别" align="center" prop="reportTags" width="250" />
        <el-table-column label="报告类型" align="center" prop="reportType" />
        <el-table-column label="发布机构" align="center" prop="reportManager" />
        <el-table-column label="页数" align="center" sortable prop="reportPages" />
        <el-table-column label="操作" width="180px" align="center">
          <template v-slot="{ row }">
            <button-table
              content="查看"
              icon="jr-iconjr-icon-eye"
              @buttonClick="seeClick(row)"
            >
            </button-table>
            <button-table @buttonClick="editItem(row)" content="编辑" icon="jr-iconjr-icon-edit" />
            <button-table
              content="删除"
              @buttonClick="deleteProuct(row)"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="page"
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import ButtonTable from '../../../components/ButtonTable.vue'
import Popover from './Popover.vue'
import { getReportingData, deleteReport } from '../../../api/report'
import { exportData } from '@/utils/tool'
import { pagination } from '../../../mixins/pagination'
// import Popover from "../../components/Popover.vue";
export default {
  mixins: [pagination],
  components: { Popover, ButtonTable },
  name: 'ReportingNumber',
  data() {
    return {
      tableData: [], //表格数据
      multipleTable: [], //勾选的数组
      multiple: [],
      total: null,
      isShow: false,
      reportManager: '',
      queryInfo: {
        pageSize: 10, //条数
        pageNum: 1, //页数
      },
    }
  },
  created() {
    this.reportManager = this.$route.query.institutionName
    this.search()
    setTimeout(() => {
      this.isShow = true
    }, 600)
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },
    reportManagers() {
      return this.$route.query.reportManager
    },
  },
  methods: {
    //获取表格数据
    async search() {
      if (this.reportManager) {
        Object.assign(this.queryInfo, { reportManager: this.reportManager })
        const res = await getReportingData(this.queryInfo)
        if (res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
        }
      } else {
        Object.assign(this.queryInfo, { reportManager: this.reportManagers })
        const res = await getReportingData(this.queryInfo)
        if (res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
        }
      }
    },
    //编辑
    editItem(row) {
      if (this.reportManager) {
        this.$router.push({
          name: 'ReportDataDetails',
          query: {
            reportId: row.reportId,
            ReportDataType: 1,
            reportManager: this.reportManager,
          },
        })
      } else {
        this.$router.push({
          name: 'ReportDataDetails',
          query: {
            reportId: row.reportId,
            ReportDataType: 1,
            reportManager: this.reportManagers,
          },
        })
      }
    },
    //批量删除
    deleteBatch() {
      if (this.multipleTable.length < 1) {
        this.$message({
          type: 'warning',
          message: '请选择需要删除的数据项',
        })
        return
      }
      this.$confirm('确认删除吗 ?')
        .then(async () => {
          const res = await deleteReport(this.multipleTable)
          if (res.code == 200) {
            this.$message.success('删除成功！')
            this.search()
          } else {
            this.$message.error('删除失败，请稍候重试')
          }
        })
        .catch(() => {})
    },
    //删除单个报告
    deleteProuct(row) {
      this.$confirm('确认删除吗 ?')
        .then(async () => {
          const res = await deleteReport([row.reportId])
          if (res.code == 200) {
            this.$message.success('删除成功！')
            this.search()
          } else {
            this.$message.error('删除失败，请稍候重试')
          }
        })
        .catch(() => {})
    },
    //勾选
    handleSelectionChange(val) {
      this.multiple = val
      let arr = []
      val.forEach((item) => {
        arr.push(item.reportId)
      })
      this.multipleTable = arr
    },
        seeClick(row) {
      if (row.reportKeys) {
        this.fileUrl = row.reportKeys;
        window.open(this.fileUrl);
        // this.isViewPdf20 = true;
      } else {
        this.$alert("该报告暂无附件!");
      }
    },
    // 导出
    popoverClick(val) {
      if (val === 'all') {
        if (this.reportManager) {
          Object.assign(this.queryInfo, { reportManager: this.reportManager })
        } else {
          Object.assign(this.queryInfo, { reportManager: this.reportManagers })
        }
      } else if (val == 0 && this.multipleTable.length) {
        Object.assign(this.queryInfo, {
          reportIds: this.multipleTable,
        })
      } else {
        Object.assign(this.queryInfo, { size: val })
      }
      exportData(
        this.queryInfo, // 后台需要的参数
        '/reportInstitution/downZip', // 路径
        'application/zip', // 下载的文件类型
        this.search, // 下载完之后调查询方法
        '报告数据.zip'
      )
    },
  },
  mounted() {
    document.getElementsByClassName('bodyOne')[0].style.height =
      document.body.clientHeight - 300 + 'px'
  },
}
</script>

<style lang='less' scoped>
.bodyOne {
  overflow: auto;
}
.reporting_number {
  /deep/ .el-button {
    margin-right: 10px;
  }
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px 16px;
  background-color: #fff;
  .reporting_number_button {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
}
</style>
